<template>
  <el-breadcrumb separator="/" style="margin-bottom: 25px;">
    <el-breadcrumb-item>{{prop.node1}}</el-breadcrumb-item>
    <el-breadcrumb-item>{{prop.node2}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script setup>
import {defineProps} from "vue"
const prop=defineProps({node1:{type:String},node2:{type:String}})

</script>
