import axios from 'axios'
import router from '@/router/index'
import storage from '@/model/storage'
import JSONbig from 'json-bigint'
// import {showToast} from "vant";

const service = axios.create({
  timeout: 60000, // 请求超时时间毫秒
  transformResponse: [function (data) {
    if((typeof data)=='string') return JSONbig.parse(data)
    return data
  }],
})


// 拦截器
service.interceptors.request.use(
  (config) => {
    if (storage.get('token')) config.headers.Authorization = "Bearer "+storage.get('token')
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截  401 token过期处理
service.interceptors.response.use(
  (response) => {
    return response
  }, (error) => {
    const { status } = error.response
    if (status == 401) {
      // showToast("登录过期，请重新登录。")
      // 清除token
      storage.set('is_login', false)
      storage.remove('token')
      // 页面跳转
      router.push('/login').then((r) => {
        console.log(r)
      })
    }
    return Promise.reject(error)
  }
)

export default service
