import storage from '@/model/storage'
import { createRouter, createWebHashHistory } from 'vue-router'
//默认进入页面
const _default = "/index/index"
const hmzjguard = "/hmzjguard"
const routes = [
  {
    path: '/',
    redirect: _default,
  },
  {
    path: '/home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      requireAuth: true
    },
    redirect: _default,
    children: [
      {
        path: '/article/index',
        component: () => import('../views/article/IndexView')
      },
      {
        path: '/article/article_class',
        component: () => import('../views/article/ArticleClass')
      },

      {
        path: '/product/attrlist',
        component: () => import('../views/product/AttrList')
      },
      {
        path: '/home/home',
        component: () => import('../views/home/HomeHtml')
      },
      {
        path: '/index/index',
        component: () => import('../views/index/IndexView.vue')
      },

      {
        path: '/banner/bannerList',
        component: () => import('../views/banner/BannerList.vue')
      },
      {
        path: '/order/orderList',
        component: () => import('../views/order/OrderList.vue')
      },
      /**管理员列表**/
      {
        path: '/admins',
        component: () => import('@/views/user/UserList.vue')
      },
      //权限管理
      {
        path: '/roles',
        component: () => import('@/views/user/RolesList.vue')
      },
      //用户列表
      {
        path: '/custom/CustomList',
        component: () => import('@/views/custom/CustomList')
      },

      //商家
      {
        path: '/merchant/MerchantList',
        component: () => import('@/views/merchant/MerchantList')
      },

      //分类列表
      {
        path: '/classify/ClassifyList',
        component: () => import('@/views/classify/ClassifyList.vue')
      },
      //品牌管理
      {
        path: '/brand/BrandList',
        component: () => import('@/views/brand/BrandList.vue')
      },
      //产品列表
      {
        path: '/product/ProductList',
        component: () => import('@/views/product/ProductList.vue')
      },
      //交易记录
      {
        path: '/finance/transaction',
        component: () => import('@/views/finance/TransactionView')
      },
      //转账记录
      {
        path: '/finance/transferRecords',
        component: () => import('@/views/finance/TransactionRecord')
      },
      //商户退款记录
      {
        path: '/finance/refundRecord',
        component: () => import('@/views/finance/RefundRecord')
      },
      //项目利润表
      {
        path: '/finance/profitStatement',
        component: () => import('@/views/finance/ProfitStatement')
      },
      //项目利润表
      {
        path: '/finance/orderFlow',
        component: () => import('@/views/finance/OrderFlow')
      },
      //项目利润表
      {
        path: '/channel/list',
        component: () => import('@/views/channel/ChannelList')
      },
      //提现列表
      {
        path: '/channel/withdraw',
        component: () => import('@/views/channel/WithdrawList')
      },
      //商品置顶
      {
        path: '/home/Topping',
        component: () => import('@/views/home/ToppingList')
      },
      //反馈列表
      {
        path: '/feedback/FeedbackList',
        component: () => import('@/views/feedback/FeedbackList')
      },
      //反馈列表
      {
        path: '/setting',
        component: () => import('@/views/setting/SettingDetail')
      },
      //反馈列表
      {
        path: '/charts/chartsList',
        component: () => import('@/views/charts/ChartsList')
      },
      //渠道订单
      {
        path: '/channel/order_list',
        component: () => import('@/views/channel/ChannelOrder')
      },
      ///finance/overdue
      //逾期统计
      {
        path: '/finance/overdue',
        component: () => import('@/views/finance/OverdueWord')
      },
      //渠道规则配置
      {
        path: '/channel/rule',
        component: () => import('@/views/channel/RuleWord')
      },

    ]
  },
  {
    path: '/login',
    component: () => import('../views/LoginView.vue')
  },
  {
    // 匹配为定义路由然后重定向到404页面
    path: '/:pathMath(.*)',
    redirect: '/404'
  },
  {
    // 定义404路由
    path: '/404',
    component: () => import('../views/NotfoundView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (window.location.href.indexOf(hmzjguard) == -1) {
    return 
  }
  if (to.matched.some((r) => r.meta.requireAuth)) {
    if (storage.get('token')) {
      next() //有token,进行request请求，后台还会验证token
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    next() //如果无需token,那么随它去吧
  }
})

export default router
